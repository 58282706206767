@tailwind base;
@tailwind components;
@tailwind utilities;

/* Optional: Import the fonts */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@400;700&display=swap');

body {
  margin: 15px;
  max-height: 60vh;
  /* background-color: rgb(249, 250, 251); */
  background: linear-gradient(145deg, rgb(246, 248, 252) 0%, rgb(240, 244, 248) 100%);
  /* background: linear-gradient(rgba(249, 250, 251, 0.9), rgba(249, 250, 251, 0.9)), url("https://minimal-kit-react.vercel.app/assets/background/overlay_4.jpg"); */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
